import React, { Component } from "react";
import { Helmet } from "react-helmet";

// header
// import AppHeader from "../components/AppHeader/AppHeader";

// localstorage
// import LS from "../utils/LocalStorageService";

// component
import TwinStorePayment from "../components/PaymentStatus/twinStorePayment";

class OrderPlaced extends Component {
  constructor(props) {
    super(props);
    this.state = {
      appHeader: "",
    };
  }

  componentDidMount() {
    // const lsValue = LS.getValue("header");
    // console.log("lsValue", lsValue);
    // if (lsValue) {
    //   this.setState({ appHeader: lsValue.appHeader });
    // }

    // Event for ios/android mobile app for app header back button
    if (window.twinApp) {
      let obj = { action: "hideBackButton" };
      let data = JSON.stringify(obj);
      window.twinApp.postMessage(data);
    } else if (window.webkit) {
      window.webkit.messageHandlers.twinApp.postMessage({
        action: "hideBackButton",
      });
    } else {
      console.log("Hiding back button working on web!");
    }
  }

  render() {
    // const { appHeader } = this.state;

    return (
      <div className="container">
        <Helmet>
          <title>Twin Store</title>
          <meta
            name="description"
            content="Exclusive Store For Existing Twin Members"
          />
        </Helmet>

        <div style={{ maxWidth: "768px", margin: "auto" }}>
          {/* {appHeader !== "no" && <AppHeader text="TWIN STORE" isBack={false} />} */}
          <TwinStorePayment />
        </div>
      </div>
    );
  }
}

export default OrderPlaced;
