import React, { Component } from "react";
import { Image } from "cloudinary-react";

//config
import Config from "../../config";

//style
import * as style from "./twinStorePayment.module.css";

class twinStorePayment extends Component {
  // sending payment success event to android/ios app
  handlePaymentSuccessMobileApp = () => {
    if (window.twinApp) {
      let data = JSON.stringify({ action: "store_payment_success" });
      window.twinApp.postMessage(data);
      // console.log("Payment completion working on android!");
    } else if (window.webkit) {
      window.webkit.messageHandlers.twinApp.postMessage({
        action: "store_payment_success",
      });
      // console.log("Payment completion working on ios!");
    } else {
      console.log("Payment completion working on web!");
    }
  };

  render() {
    return (
      <div className={`${style.background}`}>
        <div className={`${style.container1}`}>
          <div className={`${style.main_container}`}>
            <div className="mt-3">
              <Image
                secure="true"
                cloudName={Config.CLOUDINARY_NAME}
                publicId="app-images/green_check_icon_2x_qml07i"
                dpr="auto"
                responsive
                crop="scale"
                responsiveUseBreakpoints="true"
                fetchFormat="webp"
                quality="auto"
                width="60"
                height="60"
              />
            </div>
            <div>
              <p className={`${style.orderPlaced}`}>ORDER PLACED </p>
              <p className={`${style.text_1}`}>
                Your order will be delivered in 4 business days
              </p>
              <p className={`${style.text_2}`}>
                We've sent you an invoice by email.{" "}
              </p>
            </div>
            <button
              className={`${style.radio_btn}`}
              onClick={this.handlePaymentSuccessMobileApp}
              type="button"
            >
              {" "}
              <span> GOT IT</span>{" "}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default twinStorePayment;
